/* Basic editor styles */
.ProseMirror {
  padding: 16px 4px;
}

.ProseMirror-focus {
  outline: none;
}

.ProseMirror ul {
  padding: 0 2rem;
}

.ProseMirror ol {
  padding: 0 2rem;
}

.character-count {
  color: #868e96;
  margin-top: 1rem;
}
